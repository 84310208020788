import { useContext, useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import Head from 'next/head';
import KustomComponent from '@/lib/kustomcms-sdk/lib/components/KustomComponent';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import loaderAnimationData from '../lotties/loader.json';

export const loaderLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const HOME_PAGECODE = '4onpu';

export default function Home(props: any) {
  const pagesContext = useContext(PagesContext);
  const langContext = useContext(LanguageContext);

  const content = pagesContext.pages.find(
    (page) => page.pageCode === HOME_PAGECODE,
  );

  return (
    <Box>
      <Head>
        <title>
          {content?.metaTitle?.[langContext.currentLang || 'fr'] ||
            'La Maison Nova'}
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"
        />
        <meta
          name="description"
          content={content?.metaDescription?.[langContext.currentLang || 'fr']}
        />
      </Head>
      {content?.components?.map((component) => (
        <KustomComponent
          key={component.id}
          component={component}
          page={content}
        />
      ))}
    </Box>
  );
}
